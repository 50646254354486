import React from "react";
import SEO from "@components/ui/seo";
import Hero from "@components/ui/hero";
import Spacing from "@components/ui/spacing";
import Sponsors from "@components/ui/sponsors";
import ActionLink from "@components/ui/action-link";
import EmptySpace from "@components/ui/empty-space";
import ProfileCard from "@components/ui/profile-card";
import Layout from "@components/domain/shared/layout";
import BackgroundImage from "gatsby-background-image";
import { graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import PropTypes from "prop-types";
import styles from "@styles/pages/sobre-nos.module.scss";

const team = [
  {
    name: "Francisco Gonçalves",
    excerpt: "Psicólogo. PhD em Psicologia e Criminologia",
    imageFilename: "francisco-goncalves",
  },
  {
    name: "João Coelho",
    excerpt: "Físico. Cientista de Dados",
    imageFilename: "joao-coelho",
  },
  {
    name: "João Rico",
    excerpt: "Físico. Aluno PhD em Machine Learning",
    imageFilename: "joao-rico",
  },
  {
    name: "Pedro Marcelino",
    excerpt: "Engenheiro. PhD em Machine Learning",
    imageFilename: "pedro-marcelino",
  },
];

const getImageNodeByName = (name, images) => {
  const edge = images.edges.find((edge) =>
    String(edge.node.name).includes(name)
  );
  if (edge) {
    return edge.node;
  }

  return null;
};

const renderMembers = (team, allImages) => {
  return team.map((member) => {
    const imageNode = getImageNodeByName(member.imageFilename, allImages);
    const imageUrl = imageNode
      ? { publicURL: imageNode.publicURL, name: imageNode.name }
      : null;

    return (
      <div key={member.name}>
        <ProfileCard
          name={member.name}
          profession={member.excerpt}
          imageUrl={imageUrl}
          desktop={{ size: 255 }}
          mobile={{ size: 158 }}
        />
      </div>
    );
  });
};

const About = (props) => {
  const breakpoints = useBreakpoint();
  const heroImage = getImageNodeByName(
    breakpoints.mobile ? "hero-background-mobile" : "hero-background",
    props.data.heroImages
  );
  const gridConfig = breakpoints.mobile
    ? {
        minMax: 158,
        gridColumnGap: 29,
        gridRowGap: 36,
      }
    : {
        minMax: 255,
        gridColumnGap: "30px",
        gridRowGap: "40px",
      };

  return (
    <React.Fragment>
      <Hero heroImage={heroImage.childImageSharp.fluid}>
        <h2
          style={{
            fontSize: 50,
            maxWidth: 315,
            display: breakpoints.mobile ? "none" : "block",
          }}
        >
          O que há para além da escola?
        </h2>
      </Hero>
      <Layout sideNote="scroll">
        <SEO
          title="Sobre nós"
          description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
          image={{
            src: heroImage.childImageSharp.fixed.src,
            height: heroImage.childImageSharp.fixed.height,
            width: heroImage.childImageSharp.fixed.width,
            name: heroImage.name,
            mediaType: heroImage.internal.mediaType,
          }}
        />

        <Spacing>
          <EmptySpace
            desktop={{ margin: 60, isLineVisible: false }}
            mobile={{ margin: 43, isLineVisible: false }}
          />

          <section className={styles.mainDescription}>
            <h2>O que há para além da escola?</h2>
            <p>
              Somos um grupo de jovens cientistas, engenheiros e médicos que
              acredita que há inúmeras crianças e jovens com talento e
              curiosidade para a ciência. No entanto, ao contrário do que
              acontece para a música e para o desporto, não existem atividades
              com a frequência e a intensidade necessárias para que estas
              crianças e jovens desenvolvam o seu potencial. É por isso que o
              TreeTree2 existe.
            </p>
            <p>
              Através da criação das atividades que &apos;nós gostaríamos de ter tido
              quando éramos mais novos&apos;, juntamos uma comunidade de pessoas que
              vê a aprendizagem como um modo de vida. Quando assim é, aprender
              torna-se um verbo que se conjuga no plural, pois não há melhor
              aprendizagem que a partilhada com pessoas que têm o mesmo nível de
              interesse, energia e fascínio por um tema.
            </p>
            <p>
              Assim, o After School é mais um dos nossos projetos. Um projeto
              que marca um caminho com mais de cinco anos, que começou com
              palestras nas nossas antigas escolas, progrediu para uma academia
              de verão com centenas de alunos, continuou com uma incubadora de
              projetos de aprendizagem e, agora, chega a este formato que
              alcança todo o país. Para nós, não tem havido limites à nossa
              curiosidade. Esperamos que hoje percebas que também não tem de
              haver limites para a tua.
            </p>
            <p>Pronto para ir além da escola?</p>
            <ActionLink
              path="/candidatura"
              label="sabe como"
              width="160px"
              type="dark"
            />
          </section>

          <EmptySpace
            desktop={{ margin: 140, isLineVisible: false }}
            mobile={{ margin: 100, isLineVisible: false }}
          />
        </Spacing>

        <BackgroundImage
          style={{ height: 400, width: "100%" }}
          fluid={
            getImageNodeByName("writing-on-paper", props.data.heroImages)
              .childImageSharp.fluid
          }
        />

        <Spacing>
          <EmptySpace
            desktop={{ margin: 140, isLineVisible: false }}
            mobile={{ margin: 120, isLineVisible: false }}
          />

          <div className={styles.description}>
            <div className={styles.yellowFloatingLine}></div>
            <section className={styles.heading}>
              <p>
                O After School funciona num regime de aula invertida (flipped
                classroom). Isto significa que, em casa, os alunos leem, pensam
                e assistem a vídeos com os conteúdos programáticos. Nas aulas,
                resolvem exercícios e esclarecem dúvidas com os nossos
                instrutores.
              </p>

              <p>
                No After School, o acompanhamento da aprendizagem e a seleção
                dos conteúdos são feitos por professores, engenheiros,
                investigadores e alunos de excelência do ensino nacional.
              </p>
            </section>
            <section className={styles.details}>
              <label>Com o After School vais:</label>

              <div className={styles.bullets}>
                <ul>
                  <li>
                    <strong>Aprender Ciência.</strong> O currículo proposto pelo
                    After School é único em Portugal, permitindo uma
                    aprendizagem maior e mais vasta.
                  </li>

                  <li>
                    <strong>Desenvolver competências.</strong> A forma como o
                    After School funciona promove o desenvolvimento das tuas
                    competências de resiliência, autorregulação e metacognição.
                  </li>

                  <li>
                    <strong>Fazer amigos.</strong> Ao entrar no After School,
                    passas a pertencer a uma comunidade de pessoas que partilham
                    o teu interesse e motivação por aprender mais.
                  </li>
                </ul>
              </div>
            </section>
          </div>

          <EmptySpace
            desktop={{ margin: 180, isLineVisible: false }}
            mobile={{ margin: 80, isLineVisible: false }}
          />

          {team && team.length > 0 && (
            <section className={styles.team}>
              <h2 className={styles.header}>A nossa equipa</h2>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(auto-fill, minmax(${gridConfig.minMax}px, 1fr))`,
                  gridColumnGap: gridConfig.gridColumnGap,
                  gridRowGap: gridConfig.gridRowGap,
                  marginTop: 60,
                  justifyItems: "center",
                }}
              >
                {renderMembers(team, props.data.profileImages)}
              </div>
            </section>
          )}

          <EmptySpace
            desktop={{ margin: 160, isLineVisible: true }}
            mobile={{ margin: 120, isLineVisible: true }}
          />

          <Sponsors />

          <EmptySpace
            desktop={{ margin: 140, isLineVisible: false }}
            mobile={{ margin: 80, isLineVisible: false }}
          />
        </Spacing>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query AboutUsQuery {
    profileImages: allFile(
      filter: { relativeDirectory: { eq: "domain/about-us" } }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid(quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    heroImages: allFile(
      filter: { name: { regex: "/(hero-background|writing-on-paper)/" } }
    ) {
      edges {
        node {
          name
          publicURL
          internal {
            mediaType
          }
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
            fixed {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export default About;

About.propTypes = {
  data: PropTypes.shape({
    profileImages: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    heroImages: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};
